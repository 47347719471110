import * as React from 'react';
import {
    PayPalScriptProvider,
    PayPalButtons,
} from "@paypal/react-paypal-js";

const amount = "40";
const currency = "USD";
const style = {"layout":"vertical"};

const options = {
    currency: "USD",
    intent: "capture",
    'client-id': 'AU5alWvRCrqv3UaPnsXNapViyiSsKN5Y4Xal8z6ftcje_Cf-jGzaWX654unziDm_vYHWktWTm7RdDePf',
    'enable-funding': 'venmo'
}

const PaypalButton = () => {
    return <PayPalScriptProvider options={options}>
        <div id="smart-button-container">
            <div style={{textAlign: 'left'}}>
            <div style={{marginBottom: '1.25rem'}}>
            <select id="item-options" style={{ border: '1px solid #fff', borderRadius: 8, padding: 8, boxSizing: 'border-box' }}>
                <option value="Primera consulta" price="40">Primera consulta - 40 USD</option>
                <option value="Seguimiento" price="30">Seguimiento - 30 USD</option>
            </select>
            <select style={{visibility: 'hidden'}} id="quantitySelect"></select>
            </div>
            <div id="paypal-button-container"></div>
            </div>
            </div>
            <PayPalButtons
            style={style as any}
            disabled={false}
            forceReRender={[amount, currency, style]}
            createOrder={(data: any, actions: any) => {
                const itemOptions = document.querySelector("#smart-button-container #item-options") as any;
                let quantity;
                const quantitySelect = document.querySelector("#smart-button-container #quantitySelect") as any;
                const orderDescription = 'Consulta de nutrición';
                const selectedItemDescription = itemOptions.options[itemOptions.selectedIndex].value;
                const selectedItemPrice = parseFloat(itemOptions.options[itemOptions.selectedIndex].getAttribute("price"));
                let tax = (0 === 0 || false) ? 0 : (selectedItemPrice * (parseFloat('0')/100));
                if(quantitySelect.options.length > 0) {
                    quantity = parseInt(quantitySelect.options[quantitySelect.selectedIndex].value);
                } else {
                    quantity = 1;
                }

                tax *= quantity;
                tax = Math.round(tax * 100) / 100;
                let priceTotal = quantity * selectedItemPrice + parseFloat('0') + tax;
                priceTotal = Math.round(priceTotal * 100) / 100;
                const itemTotalValue = Math.round((selectedItemPrice * quantity) * 100) / 100;

                return actions.order.create({
                    purchase_units: [{
                    description: orderDescription,
                    amount: {
                        currency_code: 'USD',
                        value: priceTotal,
                        breakdown: {
                        item_total: {
                            currency_code: 'USD',
                            value: itemTotalValue,
                        },
                        shipping: {
                            currency_code: 'USD',
                            value: 0,
                        },
                        tax_total: {
                            currency_code: 'USD',
                            value: tax,
                        }
                        }
                    },
                    items: [{
                        name: selectedItemDescription,
                        unit_amount: {
                        currency_code: 'USD',
                        value: selectedItemPrice,
                        },
                        quantity: quantity
                    }]
                    }]
                });
            }}
            onApprove={function (data: any, actions: any) {
                return actions.order.capture().then(function(orderData: any) {
                                
                    // Full available details
                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                    // Show a success message within this page, e.g.
                    const element = document.getElementById('paypal-button-container') as any;
                    element.innerHTML = '';
                    element.innerHTML = '<h3>Thank you for your payment!</h3>';

                    // Or go to another URL:  actions.redirect('thank_you.html');

                });
            }}
        />
    </PayPalScriptProvider>
}

export default PaypalButton;
