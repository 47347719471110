import styled from "styled-components";

export const ChevronElement = styled.div<{ isOpen?: boolean }>`
    &::before {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        position: relative;
        transition: transform 0.1s linear;
        transform: ${({ isOpen }) => isOpen ? 'rotate(-45deg)' : 'rotate(135deg)'};
        margin: 4px 0;
    }
`;
