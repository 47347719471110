import * as React from 'react';
import { PropsWithChildren } from "react";
import { P } from '../../styles';
import Chevron from '../Chevron';
import { AnimatedContent, Container, Flex } from "./styles";

interface Props {
    title: string;
}

const Dropdown: React.FC<Props & PropsWithChildren> = ({ title, children }) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    return <Container>
        <Flex onClick={handleOpen} role="button">
            <P animation>{title}</P>
            <Chevron isOpen={open} />
        </Flex>
        <AnimatedContent open={open}>
            {children}
        </AnimatedContent>
    </Container>
}

export default Dropdown;
