import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    cursor: pointer;
`

export const AnimatedContent = styled.span<{ open?: boolean }>`
    display: ${({ open }) => open ? 'flex' : 'none'};
    flex-direction: column;
    font-size: 16px;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;

    p {
        margin-right: 16px;
    }
`;
