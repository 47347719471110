import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Dropdown from "../components/Dropdown"
import Layout from "../components/Layout"
import MobileWrapper from "../components/MobileWrapper"
import PaypalButton from "../components/PaypalButton"
import { Content, H1, Link, P, SmallP } from "../styles"

const ServiciosPage = () => {

  return (
    <Layout>
      <MobileWrapper>
        <StaticImage
          placeholder="blurred"
          src="../images/services.png"
          alt="home image"
          style={{ maxWidth: '50%', height: 'auto', position: 'absolute', right: 96 }}
        />
      </MobileWrapper>
      <Content>
        <H1 animation>
          Servicios
        </H1>
        <P animation>
          <SmallP>¿Tenés endometriosis, adenomiosis, dolor menstrual sin causa aparente, síndrome de ovario poliquístico, miomas o hipotiroidismo?</SmallP>
          <SmallP>¿Te ofrecen solo medicación y no alcanza para mejorar los síntomas que tenés?</SmallP>
          <SmallP>¿Sabés que la medicación no resuelve tu problema de base y querés avanzar a un tratamiento que genere cambios reales en tu salud?</SmallP>
          <SmallP>¿Te cansaste de intentar hacer cambios sin una guía personalizada y querés algo especial para vos?</SmallP>
          <SmallP>Te puedo ayudar. Imaginate entender cómo funciona tu cuerpo, qué necesita y cómo podés hacer para estar mejor.</SmallP>

          <Dropdown title="Taller Virtual de Endometriosis y Estilo de Vida">
            <SmallP>El taller es grabado y en cualquier momento que te anotes siempre vas a tener 6 semanas para acceder al material las veces que necesites y en ese tiempo me vas a poder consultar por mail las dudas que tengas.</SmallP>
            <SmallP>Está pensado para que avances a tu ritmo y armes tu plan de acción para un tratamiento integrativo.</SmallP>
            <SmallP>¿Qué incluye el taller?</SmallP>
            <SmallP>Acceso a 5 videos para con explicaciones y claves para poder mejorar.</SmallP>
            <SmallP>Una guía para ayudarte a definir tus objetivos de cambio.</SmallP>
            <SmallP>Un recetario para que te sea más fácil incluir algunos alimentos y lograr algunos reemplazos de alimentos poco recomendados.</SmallP>
            <SmallP>¿Qué temas voy a ver en el taller?</SmallP>
            <SmallP>Endometriosis, que es, cómo se diagnostica, qué se sabe hasta el momento y qué pasa dentro del cuerpo.</SmallP>
            <SmallP>Claves para el tratamiento integrativo:</SmallP>
            <SmallP>Endometriosis y alimentacion. Ejemplos de menús y distribución diaria.</SmallP>
            <SmallP>Endometriosis y especias y alimentos antiinflamatorios.</SmallP>
            <SmallP>Endometriosis y descanso.</SmallP>
            <SmallP>Endometriosis y actividad física.</SmallP>
            <SmallP>Endometriosis y suplementación.</SmallP>
            <SmallP>Endometriosis y contaminantes ambientales.</SmallP>
            <SmallP>Endometriosis y otras herramientas no nutricionales ni tradicionales.</SmallP>
            <SmallP>¿Como se si este taller es para mi?</SmallP>
            <SmallP>Es para vos si:</SmallP>
            <SmallP>Tenes endometriosis y pese a los tratamientos médicos tenés malestar.</SmallP>
            <SmallP>Tenes endometriosis y no tenés síntomas, pero queres cuidar a tu cuerpo.</SmallP>
            <SmallP>Tenes sospecha de endometriosis.</SmallP>
            <SmallP>Tenés dolores menstruales intesnsos, quizás solo te indicaron anticonceptivos o te dijeron que era normal. Este taller te puede ayudar.</SmallP>
            <SmallP>Información sobre el valor y pago:</SmallP>
            <SmallP>El valor es de $4500 y podés abonar <a href="https://mpago.la/1mixmb9" target="_blank">acá</a></SmallP>
            <SmallP>Si estás fuera de Argentina, el valor es de USD 30 y podés abonar <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=DNZHAWPZZBJV6" target="_blank">acá</a></SmallP>
            <SmallP>Una vez que abones, enviá el comprobante de pago al correo electrónico <a href="mailto:remediosmsanchez@gmail.com">remediosmsanchez@gmail.com</a> junto con tu nombre y número de teéfono.</SmallP>
          </Dropdown>
          <Dropdown title="Consultas personalizadas">
            <SmallP>¿Cómo son?</SmallP>
            <SmallP>En la primera consulta indagamos en tus síntomas, diagnóstico, rutina, gustos y hábitos, tanto en lo alimentario como en otros factores que también influyen en la salud hormonal.</SmallP>
            <SmallP>En base a eso definimos juntas los objetivos y las metas para alcanzarlos. Así nos asegurarnos de elegir cambios que veas posibles, pero que también te aproximen a la mejoría que buscás.</SmallP>
            <SmallP>En las consultas de seguimiento vas a ir aprendiendo cosas nuevas y vamos ajustando lo necesario para seguir avanzando.</SmallP>
            <SmallP>¿Cada cuanto son?</SmallP>
            <SmallP>No hay una frecuencia determinada para vernos, pero normalmente sugiero cada tres o cuatro semanas para que tengas tiempo de ir poniendo en práctica lo sugerido y puedas notar cómo te resulta.</SmallP>
            <SmallP>¿Qué incluye?</SmallP>
            <SmallP>Luego de 48 a 72 horas hábiles recibís una guía alimentaria, un recetario e información anexa que te pueda servir.</SmallP>
            <SmallP>Durante todo el proceso vas a tener contacto directo conmigo para preguntarme tus dudas o cualquier consulta que te pueda surgir.</SmallP>
            <SmallP>¿Qué vas a lograr con las consultas?</SmallP>
            <SmallP>• Vas a aprender una forma de alimentarte que te ayude a mejorar tu salud hormonal.</SmallP>
            <SmallP>• Vas a poder modular tus síntomas: reducir tu dolor y disminuir el crecimiento de los focos de endometriosis, mejorar tu síndrome premenstrual, lograr ciclos regulares en SOP, disminuir tu fatiga crónica y más.</SmallP>
            <SmallP>• Vas a aprender a distinguir qué alimentos te benefician y cuáles no.</SmallP>
            <SmallP>• Vas a aprender a hacer comidas saludables y completas.</SmallP>
            <SmallP>• Vas a poder resolver todas tus dudas en cualquier momento.</SmallP>
            <SmallP>Mi objetivo es que no te sientas sola y que todos los cambios que hagas en tu alimentación y rutina sean justo para vos. Se trata de construir hábitos nuevos que te acompañen siempre.</SmallP>
            <SmallP>¿Cómo agendo una consulta?</SmallP>
            <SmallP>Ingresá al calendario para agendar tu consulta: <a href="https://calendly.com/rsnutricion/primera-consulta" target="_blank">https://calendly.com/rsnutricion/primera-consulta</a></SmallP>
            <SmallP>Si vivis en Argentina se abona por transferencia y si vivís en el exterior podés abonar acá:</SmallP>
            <SmallP>
                <PaypalButton />
            </SmallP>
          </Dropdown>
        </P>
      </Content>
    </Layout>
  )
}

export default ServiciosPage
